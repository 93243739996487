import React from "react"
import Navbar from "./Navbar"
import "../styles/global.css"

export default function SecondaryLayout({ children }) {
  return (
    <div className="layout">
      <Navbar absolute={true} />
      <div className="content">{children}</div>
    </div>
  )
}
