import React, { Suspense, useRef, useEffect } from "react"
import "../styles/global.css"
//Components
import SecondaryLayout from "../components/SecondaryLayout"
import { Canvas, useFrame } from "react-three-fiber"
import { Section } from "../components/section"
// helpers for react-three-fiber
import { Html, useGLTF, useProgress } from "@react-three/drei"
// page state
import state from "../components/state"
// React Spring
import { a, useTransition } from "@react-spring/web"
// intersection obeserver
import { useInView } from "react-intersection-observer"

const Model = ({ modelPath }) => {
  // const gltf = useGLTF("../../models/armchairGray.gltf", true)
  const { scene } = useGLTF(modelPath, true)
  // useGLTF.preload(modelPath)

  return <primitive object={scene} dispose={null} />
}

const Lights = ({ color }) => {
  return (
    <>
      <ambientLight color={color} intensity={1} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <directionalLight position={[0, 10, 0]} intensity={1.5} />
      <spotLight position={[1000, 0, 0]} intensity={1} />
    </>
  )
}

const HTMLContent = ({
  domContent,
  children,
  modelPath,
  positionY,
  scale,
  meshPosition,
  bgColor,
}) => {
  // make the mesh spin
  const ref = useRef()
  useFrame(() => (ref.current.rotation.y += 0.007))
  const [refItem, inView] = useInView({ threshold: 0 })
  useEffect(() => {
    inView && (document.body.style.background = bgColor)
  }, [inView])

  return (
    <Section factor={1.5} offset={1}>
      <group position={[0, positionY, 0]}>
        <mesh ref={ref} position={meshPosition} scale={[scale, scale, scale]}>
          <Model modelPath={modelPath} />
        </mesh>
        <Html portal={domContent} fullscreen>
          <div ref={refItem}>{children}</div>
        </Html>
      </group>
    </Section>
  )
}

function Loader() {
  const { active, progress } = useProgress()
  const transition = useTransition(active, {
    from: { opacity: 1, progress: 0 },
    leave: { opacity: 0 },
    update: { progress },
  })
  return transition(
    ({ progress, opacity }, active) =>
      active && (
        <a.div className="loading" style={{ opacity }}>
          <div className="loading-bar-container">
            <a.div className="loading-bar" style={{ width: progress }}></a.div>
          </div>
        </a.div>
      )
  )
}

export default function Models() {
  const domContent = useRef()
  const scrollArea = useRef()
  const onScroll = e => (state.top.current = e.target.scrollTop)
  useEffect(() => void onScroll({ target: scrollArea.current }), [])

  return (
    <SecondaryLayout>
      <Canvas colorManagement camera={{ position: [0, 0, 120], fov: 70 }}>
        <Lights color="#52832a" />
        <Suspense fallback={null}>
          <HTMLContent
            domContent={domContent}
            modelPath="/models/eiffel/sceneDraco.gltf"
            meshPosition={[0, -50, 0]}
            positionY={250}
            scale={0.8}
            bgColor={"#f15946"}
          >
            <div className="container">
              <h1 className="title">Eiffel Tower</h1>
            </div>
          </HTMLContent>
          <HTMLContent
            domContent={domContent}
            modelPath="/models/liberty/yq132rlysl11.gltf"
            meshPosition={[0, -50, 0]}
            positionY={0}
            scale={65.2}
            bgColor={"#fb369b"}
          >
            <div className="container">
              <h1 className="title">Statue of Liberty</h1>
            </div>
          </HTMLContent>
        </Suspense>
      </Canvas>
      <Loader />
      {/* enable scroll down to other objects */}
      <div className="scrollArea" ref={scrollArea} onScroll={onScroll}>
        <div style={{ position: "sticky", top: 0 }} ref={domContent} />
        {/* state on its own component */}
        <div style={{ height: `${state.sections * 100}vh` }} />
      </div>
    </SecondaryLayout>
  )
}
